<template>
  <component ref="model" :is="getComonent(config.modelName||'Model-1')" @ready="modelReady" @Show="showed=true" @Hide="showed=false">
    <div v-if="showed" style="width:90vw;height:90vh;position:relative">
      <PanoMap ref="PanoMap" zoom="max" :currentItem="pano" :currentLoc="{lng:pano.gpsLng,lat:pano.gpsLat}" :mapCenter="{lng:pano.gpsLng,lat:pano.gpsLat}">
        <template v-slot:buttons>
          <button @click="mapBackToPano" style="line-height: 2rem;height: 2.5rem;">回到目标位置</button>
        </template>
      </PanoMap>
      <i style="position:absolute;top:3px;right:3px;padding:5px;z-index: 1000;" class="fas fa-times text-shadow" @click="$refs.model.hideModal()" title="关闭" />
    </div>
  </component>
</template>
<script>
  import PanoMap from '../../Map/PanoLeafletMap'
  export default {
    components: {
      PanoMap
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        showed: false,
        modelShow: null,
        modelHide: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          show: this.show,
          showAndNavigation: this.showAndNavigation,
        }
      })
    },
    destroyed() {
    },
    methods: {
      mapBackToPano() {
        this.$refs.PanoMap.mapBackToPano()
      },
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        console.log('mapModelShow')
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      showAndNavigation() {
        this.show()
        setTimeout(() => {
          this.$refs.PanoMap.navigation({ lng: this.pano.gpsLng, lat: this.pano.gpsLat })
        }, 1)
      },
    },
  }
</script>
<style scoped>
  article {
    text-align: justify;
  }

  .panoinfo {
    width: 70vw;
    max-width: 600px;
    min-width: 240px;
    color: #fff;
  }

  .exif {
    font-size: 1.2rem
  }

    .exif tr:first-child {
      white-space: nowrap
    }

    .exif td {
      padding: 0 0 0 0;
      border-bottom: none;
    }

    .exif th {
      padding: 0 0 0 0;
      text-align: right;
      width: 6em;
      vertical-align: top;
      border-bottom: none;
    }
</style>
